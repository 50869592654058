<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0"> {{ publicationId ? 'Edit' : 'New' }} publication<br>
                <!-- <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">Publish <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div> -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to research lines"
              @click="$router.push({name: 'researcher.publications.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Publication')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher
              :item="publication"
              :aux-permission="loggedUser.roles.includes('publication-admin')"
            />

            <WosData v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')" />

            <PublicationFullReference
              @setFillType="fillType = $event"
            />

            <PublicationFeatures />

            <AddFile
              :id="1"
              :title="'Files'"
              :visible="false"
              :type="5"
              :files="publication.files"
              :item="publication"
              @saveAnswer="saveFiles"
            />

            <Multimedia
              :item="publication"
            />
          </div>

          <AttachedForm
            :type="'Publication'"
            :model-id="publicationId"
          />
        </div>

        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
          <button
            v-if="!loading && publicationId"
            id="type-danger"
            class="btn btn-danger ms-1"
            @click="deletePubli"
          >
            <i data-feather="trash" /> <span class="ms-25 align-middle"> Delete</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import Multimedia from '../activities/components/Multimedia.vue'
import PublicationFeatures from './components/PublicationFeatures.vue'
import WosData from './components/WosData.vue'
import PublicationFullReference from './components/PublicationFullReference.vue'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    SearchResearcher,
    Multimedia,
    WosData,
    PublicationFeatures,
    PublicationFullReference,
    AddFile,
    AttachedForm,
  },
  data() {
    return {
      publicationId: this.$route.params.id,
      loading: false,
      fillType: null,
    }
  },
  computed: {
    ...mapGetters({
      publication: 'publications/item',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'publications.publications')
    if (this.publicationId) {
      await this.$store.dispatch('publications/fetchId', this.publicationId)
    } else {
      await this.$store.dispatch('publications/cleanType')
    }
  },
  methods: {
    async save() {
      this.loading = true
      this.publication.attached_form = this.attached

      const data = {
        ...this.publication,
      }

      if (this.loggedUser.roles.includes('researcher') && !this.loggedUser.roles.includes('publication-admin')) {
        data.user = this.loggedUser
        data.roleResearcherUser = true
      }

      if (this.fillType === 'paste') {
        data.fillTypePaste = true
      } else if (this.fillType === 'manually') {
        data.fillTypeManually = true
        if (this.publication.authors) {
          this.getReference()
        } else {
          Vue.swal('Error - Required information missing', 'The authors field is required.', 'error')
          this.loading = false
          return
        }
      }

      if (this.publication.publication_type && this.publication.publication_type.name === 'Article in a Journal') {
        data.typeArticleInAJournal = true
      }

      try {
        if (this.publicationId) {
          await this.$store.dispatch('publications/update', data)
        } else {
          await this.$store.dispatch('publications/create', data)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      this.loading = false
      if (this.errors === '' && this.publication.id) {
        Vue.swal('Publication updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.publications.index' })
        })
      }
    },
    saveFiles(files) {
      this.publication.files_new = files.data
    },
    async deletePubli() {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'researcher.publications.index', url: `${Vue.prototype.$groupUrl}/publications/${this.publicationId}`, requiredFetch: false })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    getReference() {
      let link = ''
      if (this.publication.publication_type && !this.publication.publication_type.show_all_full_reference) {
        link = `'<em><a href="${this.publication.link_publication}">${this.publication.publication_name}</a></em>'`
      } else {
        link = `'<a href="${this.publication.link_publication}">${this.publication.title}</a>', <em>${this.publication.publication_name}</em>`
      }

      this.publication.full_reference = `${this.publication.authors.replaceAll(',', '').replaceAll(';', ',')}, ${this.publication.year} ${link}, ${this.publication.noservol_pages ?? (this.publication.wos_data && this.publication.wos_data.noservol_pages ? this.publication.wos_data.noservol_pages : '')}`
    },
  },
}
</script>
